import React from 'react';
import feature_icon from "../assets/images/home-1/feature-icon.png";
import feature_icon_2 from "../assets/images/home-1/feature-icon-2.png";
import feature_icon_3 from "../assets/images/home-1/feature-icon-3.png";
import feature_icon_4 from "../assets/images/home-1/feature-icon-4.png";
import about_thumb from "../assets/images/home-1/about-thumb.png";
import about_icon1 from "../assets/images/home-1/about-icon1.png";
import about_icon2 from "../assets/images/home-1/about-icon2.png";
import about_author from "../assets/images/home-1/about-author.jpg";
import about_shape from "../assets/images/home-1/about-shape.jpg";
import blog1 from "../assets/images/home-1/blog1.png";
import blog2 from "../assets/images/home-1/blog2.png";
import blog3 from "../assets/images/home-1/blog3.png";

import team1 from "../assets/images/home-1/team1.jpg";
import team2 from "../assets/images/home-1/team2.jpg";
import team3 from "../assets/images/home-1/team3.jpg";
import team4 from "../assets/images/home-1/team4.jpg";

import portfolio_1 from "../assets/images/home-1/portfolio-1.jpg";
import portfolio_2 from "../assets/images/home-1/portfolio-2.jpg";
import portfolio_3 from "../assets/images/home-1/portfolio-3.jpg";
import portfolio_4 from "../assets/images/home-1/portfolio-4.jpg";
import question from "../assets/images/home-1/question.png";

import process_icon_1 from "../assets/images/home-1/process-icon-1.png";
import process_icon_2 from "../assets/images/home-1/process-icon-2.png";
import process_icon_3 from "../assets/images/home-1/process-icon-3.png";
import process_icon_4 from "../assets/images/home-1/process-icon-4.png";
import service_icon1 from "../assets/images/home-1/service-icon1.png";
import service_icon2 from "../assets/images/home-1/service-icon2.png";
import service_icon3 from "../assets/images/home-1/service-icon3.png";
import service_icon4 from "../assets/images/home-1/service-icon4.png";
import service_icon5 from "../assets/images/home-1/service-icon5.png";
import service_icon6 from "../assets/images/home-1/service-icon6.png";

const Ourcompany = () => {
  return (
      <div>       
        <div className="feature-area style-one">
          <div className="container">
            <div className="row margin-top">
              <div className="col-lg-4 col-md-6 col-xl-3">
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="flip-box-thumb">
                        <img src={feature_icon} alt="feature_icon" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Managed IT Services</h4>
                        <p>Proin Pulvinar Eu Sem Endro thes Vehicula. Integer Urna Libero and, Semper emper business.</p>
                      </div>
                      <div className="flip-box-button">
                        <a href="/service/managed-it-services">Read More<i className="fa-solid fa-angles-right" /></a>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <div className="flip-box-thumb">
                      <img src={feature_icon} alt="feature_icon" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Managed IT Services</h4>
                        <p>Proin Pulvinar Eu Sem Endro thes Vehicula. Integer Urna Libero and, Semper emper business.</p>
                      </div>
                      <div className="flip-box-button">
                        <a href="/service/managed-it-services">Read More<i className="fa-solid fa-angles-right" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-xl-3">
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="flip-box-thumb">
                       <img src={feature_icon_2} alt="feature_icon_2" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Cloud Services</h4>
                        <p>Proin Pulvinar Eu Sem Endro thes Vehicula. Integer Urna Libero and, Semper emper business.</p>
                      </div>
                      <div className="flip-box-button">
                        <a href="/service/cloud-services">Read More<i className="fa-solid fa-angles-right" /></a>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <div className="flip-box-thumb">
                      <img src={feature_icon_2} alt="feature_icon_2" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Cloud Services</h4>
                        <p>Proin Pulvinar Eu Sem Endro thes Vehicula. Integer Urna Libero and, Semper emper business.</p>
                      </div>
                      <div className="flip-box-button">
                        <a href="/service/cloud-services">Read More<i className="fa-solid fa-angles-right" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-xl-3">
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="flip-box-thumb">
                       <img src={feature_icon_3} alt="feature_icon_3" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Machine Learning</h4>
                        <p>Proin Pulvinar Eu Sem Endro thes Vehicula. Integer Urna Libero and, Semper emper business.</p>
                      </div>
                      <div className="flip-box-button">
                        <a href="/service/machine-learning">Read More<i className="fa-solid fa-angles-right" /></a>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <div className="flip-box-thumb">
                       <img src={feature_icon_3} alt="feature_icon_3" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Machine Learning</h4>
                        <p>Proin Pulvinar Eu Sem Endro thes Vehicula. Integer Urna Libero and, Semper emper business.</p>
                      </div>
                      <div className="flip-box-button">
                        <a href="/service/machine-learning">Read More<i className="fa-solid fa-angles-right" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-xl-3">
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="flip-box-thumb">
                        <img src={feature_icon_4} alt="feature_icon_4" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Data Security</h4>
                        <p>Proin Pulvinar Eu Sem Endro thes Vehicula. Integer Urna Libero and, Semper emper business.</p>
                      </div>
                      <div className="flip-box-button">
                        <a href="/service/data-security">Read More<i className="fa-solid fa-angles-right" /></a>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <div className="flip-box-thumb">
                       <img src={feature_icon_4} alt="feature_icon_4" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Data Security</h4>
                        <p>Proin Pulvinar Eu Sem Endro thes Vehicula. Integer Urna Libero and, Semper emper business.</p>
                      </div>
                      <div className="flip-box-button">
                        <a href="/service/data-security">Read More<i className="fa-solid fa-angles-right" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="about-area style-one">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-thumb">
                  <img src={about_thumb} alt="about-thumb" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-right">
                  <div className="section-title left style-one">
                    <h4>Who We Are</h4>
                    <h1>Get Exclusive It Solutions &amp;</h1>
                    <h1>Services With <span>TopTech</span></h1>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which is best for IT solution service and consulting for your business.</p>
                  </div>
                  <div className="about-icon-box">
                    <div className="about-icon-thumb">
                      <img src={about_icon1} alt="about-icon1" />
                    </div>
                    <div className="about-icon-box-content">
                      <h4>Certified Company</h4>
                      <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin lorem awesome.</p>
                    </div>
                  </div>
                  <div className="about-icon-box">
                    <div className="about-icon-thumb">
                    <img src={about_icon2} alt="about-icon2" />
                    </div>
                    <div className="about-icon-box-content">
                      <h4>Internal Networking</h4>
                      <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin lorem awesome.</p>
                    </div>
                  </div>
                  <div className="about-flex-box">
                  
                    <div className="about-author">
                      <div className="about-author-thumb">
                        <img src={about_author} alt="about-author" />
                      </div>
                      <div className="about-author-content">
                        <h4>Kevin Martin</h4>
                        <p>CEO &amp; Founder</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-shape">
              <img src={about_shape} alt="about-shape" />
            </div>
          </div>
        </div>
       
        <div className="service-area style-one">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="section-title center style-one">
                  <h4>Service We Provide </h4>
                  <h1>All Professional We’re Offering Best &amp;</h1>
                  <h1>IT Solutions &amp; <span>Services</span></h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xl-4">
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="flip-box-thumb">
                       <img src={service_icon1} alt="service-icon1" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Web Design</h4>
                        <p>Proin Pulvinar Eu Sem Eu Vehicula and, Integer Urna Libero, Semper mes.</p>
                        <div className="flip-box-button">
                          <a href="/service/web-design">Read More<i className="fa-solid fa-angles-right" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <div className="flip-box-thumb">
                       <img src={service_icon1} alt="service-icon1" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Web Design</h4>
                        <p>Proin Pulvinar Eu Sem Eu Vehicula and, Integer Urna Libero, Semper mes.</p>
                        <div className="flip-box-button">
                          <a href="/service/web-design">Read More<i className="fa-solid fa-angles-right" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-xl-4">
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="flip-box-thumb">
                       <img src={service_icon2} alt="service-icon2" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Motion Design</h4>
                        <p>Proin Pulvinar Eu Sem Eu Vehicula and, Integer Urna Libero, Semper mes.</p>
                        <div className="flip-box-button">
                          <a href="/service/motion-design">Read More<i className="fa-solid fa-angles-right" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <div className="flip-box-thumb">
                       <img src={service_icon2} alt="service-icon2" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Motion Design</h4>
                        <p>Proin Pulvinar Eu Sem Eu Vehicula and, Integer Urna Libero, Semper mes.</p>
                        <div className="flip-box-button">
                          <a href="/service/motion-design">Read More<i className="fa-solid fa-angles-right" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-xl-4">
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="flip-box-thumb">
                       <img src={service_icon3} alt="service-icon3" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Digital Marketing</h4>
                        <p>Proin Pulvinar Eu Sem Eu Vehicula and, Integer Urna Libero, Semper mes.</p>
                        <div className="flip-box-button">
                          <a href="/service/digital-marketing">Read More<i className="fa-solid fa-angles-right" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <div className="flip-box-thumb">
                       <img src={service_icon3} alt="service-icon3" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Digital Marketing</h4>
                        <p>Proin Pulvinar Eu Sem Eu Vehicula and, Integer Urna Libero, Semper mes.</p>
                        <div className="flip-box-button">
                          <a href="/service/digital-marketing">Read More<i className="fa-solid fa-angles-right" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-xl-4">
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="flip-box-thumb">
                       <img src={service_icon4} alt="service-icon4" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Web Development</h4>
                        <p>Proin Pulvinar Eu Sem Eu Vehicula and, Integer Urna Libero, Semper mes.</p>
                        <div className="flip-box-button">
                          <a href="/service/web-development">Read More<i className="fa-solid fa-angles-right" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <div className="flip-box-thumb">
                       <img src={service_icon4} alt="service-icon4" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Web Development</h4>
                        <p>Proin Pulvinar Eu Sem Eu Vehicula and, Integer Urna Libero, Semper mes.</p>
                        <div className="flip-box-button">
                          <a href="/service/web-development">Read More<i className="fa-solid fa-angles-right" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-xl-4">
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="flip-box-thumb">
                        <img src={service_icon5} alt="service-icon6" />
                      </div>
                      <div className="flip-box-content">
                        <h4>App Development</h4>
                        <p>Proin Pulvinar Eu Sem Eu Vehicula and, Integer Urna Libero, Semper mes.</p>
                        <div className="flip-box-button">
                          <a href="/service/app-development">Read More<i className="fa-solid fa-angles-right" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <div className="flip-box-thumb">
                       <img src={service_icon5} alt="service-icon6" />
                      </div>
                      <div className="flip-box-content">
                        <h4>App Development</h4>
                        <p>Proin Pulvinar Eu Sem Eu Vehicula and, Integer Urna Libero, Semper mes.</p>
                        <div className="flip-box-button">
                          <a href="/service/app-development">Read More<i className="fa-solid fa-angles-right" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>			
              <div className="col-lg-6 col-md-6 col-xl-4">
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="flip-box-thumb">
                       <img src={service_icon6} alt="service-icon6" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Creative Solution</h4>
                        <p>Proin Pulvinar Eu Sem Eu Vehicula and, Integer Urna Libero, Semper mes.</p>
                        <div className="flip-box-button">
                          <a href="/service/creative-solution">Read More<i className="fa-solid fa-angles-right" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <div className="flip-box-thumb">
                        <img src={service_icon6} alt="service-icon6" />
                      </div>
                      <div className="flip-box-content">
                        <h4>Creative Solution</h4>
                        <p>Proin Pulvinar Eu Sem Eu Vehicula and, Integer Urna Libero, Semper mes.</p>
                        <div className="flip-box-button">
                          <a href="/service/creative-solution">Read More<i className="fa-solid fa-angles-right" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div className="work-process-area style-one">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="section-title center style-one">
                  <h4>Work Process</h4>
                  <h1>Working Process For <span>Technology</span></h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="single-process-box before-transprant after-transprant">
                  <div className="process-box-thumb">
                   <img src={process_icon_1} alt="process-icon" />
                    <div className="process-number">
                      <span>1</span>
                    </div>
                  </div>
                  <div className="process-content">
                    <h4>Select A Project</h4>
                    <p>We have the technology and IT expertise to develop.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-process-box before-transprant">
                  <div className="process-box-thumb">
                   <img src={process_icon_2} alt="process-icon" />
                    <div className="process-number">
                      <span>2</span>
                    </div>
                  </div>
                  <div className="process-content">
                    <h4>Project Analysis</h4>
                    <p>We have the technology and IT expertise to develop.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-process-box after-transprant">
                  <div className="process-box-thumb">
                    <img src={process_icon_3} alt="process-icon" />
                    <div className="process-number">
                      <span>3</span>
                    </div>
                  </div>
                  <div className="process-content">
                    <h4>Start Process</h4>
                    <p>We have the technology and IT expertise to develop.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-process-box before-transprant">
                  <div className="process-box-thumb">
                    <img src={process_icon_4} alt="process-icon" />
                    <div className="process-number">
                      <span>4</span>
                    </div>
                  </div>
                  <div className="process-content">
                    <h4>Deliver Result</h4>
                    <p>We have the technology and IT expertise to develop.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="faqs-area style-one">
          <div className="container">
            <div className="row add-faqs-bg align-items-center">
              <div className="col-lg-6">
                <div className="single-contact-box">
                  <div className="contact-content">
                    <h4>Get Free Estimate</h4>
                  </div>
                  <form action="#">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="single-input-box">
                          <input type="text" name="name" placeholder="Name" required />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-box">
                          <input type="text" name="phone" placeholder="Phone" required />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-box">
                          <input type="text" name="Email" placeholder="Email" required />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-box">
                          <textarea name="Massage" placeholder="Write Message" required defaultValue={""} />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-box">
                          <button type="submit">Free Consultancy</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 faqs-right">
                <div className="section-title left style-one">
                  <h4>Work Process</h4>
                  <h1>Frequently Asked Any Kind </h1>
                  <h1>Of Questions </h1>
                </div>
                <div className="faqs-container">
                  <div className="faq-singular">
                    <h2 className="faq-question"><img src={question} alt="question" />#1 What is Technology service?</h2>
                    <div className="faq-answer">
                      <div className="desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing awesom. Optio, neque qui velit. Magni dolorum quidem
                        ipsam also as eligendi, totam, facilis laudantium.
                      </div>
                    </div>
                  </div>
                  <div className="faq-singular">
                    <h2 className="faq-question"><img src={question} alt="question" />#2 How To Buy A Product?</h2>
                    <div className="faq-answer">
                      <div className="desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing awesom. Optio, neque qui velit. Magni dolorum quidem
                        ipsam also as eligendi, totam, facilis laudantium.
                      </div>
                    </div>
                  </div>
                  <div className="faq-singular">
                    <h2 className="faq-question"><img src={question} alt="question" />#3 How Work The Support Policy?</h2>
                    <div className="faq-answer">
                      <div className="desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing awesom. Optio, neque qui velit. Magni dolorum quidem
                        ipsam also as eligendi, totam, facilis laudantium.
                      </div>
                    </div>
                  </div>
                  <div className="faq-singular">
                    <h2 className="faq-question"><img src={question} alt="question" />#4 How Can Send A Refund Request?</h2>
                    <div className="faq-answer">
                      <div className="desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing awesom. Optio, neque qui velit. Magni dolorum quidem
                        ipsam also as eligendi, totam, facilis laudantium.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="portfolio-area style-one">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title left style-one">
                  <h4>Portfolio </h4>
                  <h1>Our Completed <span>Projects</span></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="portfolio-list-1 owl-carousel">
                <div className="col-lg-12">
                  <div className="single-portfolio-box">
                    <div className="portfolio-thumb">
                     <img src={portfolio_1} alt="portfolio" />
                    </div>
                    <div className="portfolio-content">
                      <div className="portfolio-button">
                        <a href="project.html"><i className="fa-solid fa-plus" /></a>
                      </div>
                      <div className="portfolio-title">
                        <a href="project-details.html">Digital Marketing Agency</a>
                        <p>Marketing </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-portfolio-box">
                    <div className="portfolio-thumb">
                    <img src={portfolio_2} alt="portfolio" />
                    </div>
                    <div className="portfolio-content">
                      <div className="portfolio-button">
                        <a href="project.html"><i className="fa-solid fa-plus" /></a>
                      </div>
                      <div className="portfolio-title">
                        <a href="project-details.html">Digital Marketing Agency</a>
                        <p>Marketing </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-portfolio-box">
                    <div className="portfolio-thumb">
                     <img src={portfolio_3} alt="portfolio" />
                    </div>
                    <div className="portfolio-content">
                      <div className="portfolio-button">
                        <a href="project.html"><i className="fa-solid fa-plus" /></a>
                      </div>
                      <div className="portfolio-title">
                        <a href="project-details.html">Digital Marketing Agency</a>
                        <p>Marketing </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-portfolio-box">
                    <div className="portfolio-thumb">
                      <img src={portfolio_4} alt="portfolio" />
                    </div>
                    <div className="portfolio-content">
                      <div className="portfolio-button">
                        <a href="project.html"><i className="fa-solid fa-plus" /></a>
                      </div>
                      <div className="portfolio-title">
                        <a href="project-details.html">Digital Marketing Agency</a>
                        <p>Marketing </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div className="team-area style-one">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="section-title center style-one">
                  <h4>Team Member</h4>
                  <h1>Meet With Our <span>Engineers</span> </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="single-team-box">
                  <div className="team-thumb">
                    <img src={team1} alt="team1" />
                  </div>
                  <div className="team-content">
                    <div className="team-bio">
                      <h4>Arianna Bibi</h4>
                      <p>Web Developer</p>
                    </div>
                    <div className="team-social-icon">
                      <ul>
                        <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                        <li><a href="#"><i className="fa-brands fa-twitter" /></a></li>
                        <li><a href="#"><i className="fa-brands fa-youtube" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-team-box">
                  <div className="team-thumb">
                    <img src={team2} alt="team2" />
                  </div>
                  <div className="team-content">
                    <div className="team-bio">
                      <h4>Nazmul Hossen</h4>
                      <p>UI/UX Designar</p>
                    </div>
                    <div className="team-social-icon">
                      <ul>
                        <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                        <li><a href="#"><i className="fa-brands fa-twitter" /></a></li>
                        <li><a href="#"><i className="fa-brands fa-youtube" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-team-box">
                  <div className="team-thumb">
                    <img src={team3} alt="team" />
                  </div>
                  <div className="team-content">
                    <div className="team-bio">
                      <h4>Roman Soltana </h4>
                      <p>Manager</p>
                    </div>
                    <div className="team-social-icon">
                      <ul>
                        <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                        <li><a href="#"><i className="fa-brands fa-twitter" /></a></li>
                        <li><a href="#"><i className="fa-brands fa-youtube" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-team-box">
                  <div className="team-thumb">
                    <img src={team4} alt="team4" />
                  </div>
                  <div className="team-content">
                    <div className="team-bio">
                      <h4>Ghaura Mogibor </h4>
                      <p>UI/UX Developer</p>
                    </div>
                    <div className="team-social-icon">
                      <ul>
                        <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                        <li><a href="#"><i className="fa-brands fa-twitter" /></a></li>
                        <li><a href="#"><i className="fa-brands fa-youtube" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="testimonial-area style-one">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="section-title center style-one">
                  <h4>Testimonial</h4>
                  <h1>Customer’s Awesome Feedback</h1>
                  <h1>About Our Services</h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="testi-list-1 owl-carousel">
                <div className="col-lg-12 text-center">
                  <div className="single-testimonial-box">
                    <div className="testi-desc">
                      <p>Completely extend leveraged customer service rather than performance based imperatives.
                        magnetic relationships rather than leveraged e-markets. Rapidiously transform timely niches
                        technology. Enthusiastically e-enable global e-markets for cooperative e-business.
                        Authoritatively deliver highly efficient expertise</p>
                    </div>
                    <div className="testi-rating">
                      <ul>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                      </ul>
                    </div>
                    <div className="testi-author">
                      <h4>Venkatesh Iyer</h4>
                      <p>UI Designer</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 text-center">
                  <div className="single-testimonial-box">
                    <div className="testi-desc">
                      <p>Completely extend leveraged customer service rather than performance based imperatives.
                        magnetic relationships rather than leveraged e-markets. Rapidiously transform timely niches
                        technology. Enthusiastically e-enable global e-markets for cooperative e-business.
                        Authoritatively deliver highly efficient expertise</p>
                    </div>
                    <div className="testi-rating">
                      <ul>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                      </ul>
                    </div>
                    <div className="testi-author">
                      <h4>John D. Alexon</h4>
                      <p>UI Designer</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 text-center">
                  <div className="single-testimonial-box">
                    <div className="testi-desc">
                      <p>Completely extend leveraged customer service rather than performance based imperatives.
                        magnetic relationships rather than leveraged e-markets. Rapidiously transform timely niches
                        technology. Enthusiastically e-enable global e-markets for cooperative e-business.
                        Authoritatively deliver highly efficient expertise</p>
                    </div>
                    <div className="testi-rating">
                      <ul>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                      </ul>
                    </div>
                    <div className="testi-author">
                      <h4>Alex Brock </h4>
                      <p>UI Designer</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 text-center">
                  <div className="single-testimonial-box">
                    <div className="testi-desc">
                      <p>Completely extend leveraged customer service rather than performance based imperatives.
                        magnetic relationships rather than leveraged e-markets. Rapidiously transform timely niches
                        technology. Enthusiastically e-enable global e-markets for cooperative e-business.
                        Authoritatively deliver highly efficient expertise</p>
                    </div>
                    <div className="testi-rating">
                      <ul>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                        <li><i className="bi bi-star-fill" /></li>
                      </ul>
                    </div>
                    <div className="testi-author">
                      <h4>Abhishek Sharma</h4>
                      <p>UI Designer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="blog-area style-one">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="section-title center style-one">
                  <h4>Our blog Update</h4>
                  <h1>Featured News And <span>Insights</span> </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-blog-box">
                  <div className="blog-thumb">
                  <img src={blog1} alt="blog1" />                    
                  </div>
                  <div className="blog-content">
                    <div className="blog-meta">
                      <span><i className="bi bi-calendar-check-fill" /> April 22 2024</span>
                    </div>
                    <a href="/blog/22-04-2024/most-popular-chrome-extensionfor-business-promot">Most Popular Chrome Extensionfor Business Promot</a>
                  </div>
                  <div className="blog-buttom">
                    <div className="blog-author">
                      <span><i className="bi bi-person-circle" />By Admin </span>
                    </div>
                    <div className="blog-button">
                      <a href="/blog/22-04-2024/most-popular-chrome-extensionfor-business-promot">Read More<i className="bi bi-arrow-right-short" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-blog-box">
                  <div className="blog-thumb">
                    <img src={blog2} alt="blog2" />
                  </div>
                  <div className="blog-content">
                    <div className="blog-meta">
                      <span><i className="bi bi-calendar-check-fill" /> April 22 2024</span>
                    </div>
                    <a href="/blog/22-04-2024/marketing-your-area-business-downturn-now-a">Marketing Your Area Business Downturn Now A</a>
                  </div>
                  <div className="blog-buttom">
                    <div className="blog-author">
                      <span><i className="bi bi-person-circle" />By Admin </span>
                    </div>
                    <div className="blog-button">
                      <a href="/blog/22-04-2024/marketing-your-area-business-downturn-now-a">Read More<i className="bi bi-arrow-right-short" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-blog-box">
                  <div className="blog-thumb">
                    <img src={blog3} alt="blog3" />
                  </div>
                  <div className="blog-content">
                    <div className="blog-meta">
                      <span><i className="bi bi-calendar-check-fill" /> April 22 2024</span>
                    </div>
                    <a href="/blog/22-04-2024/better-context-menus-with-safe-triangles">Better Context Menus With Safe Triangles</a>
                  </div>
                  <div className="blog-buttom">
                    <div className="blog-author">
                      <span><i className="bi bi-person-circle" />By Admin </span>
                    </div>
                    <div className="blog-button">
                      <a href="/blog/22-04-2024/better-context-menus-with-safe-triangles">Read More<i className="bi bi-arrow-right-short" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Ourcompany
