import React from "react";

const Topheader = () => {
    return (
        <div>
            <div className="top-header-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="header-info">
                                <ul>
                                    <li><i className="bi bi-geo-alt-fill" />+91-8750299299</li>
                                    <li><i className="bi bi-envelope" />admin@dharamvirinfotech.com</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="top-header-social-icon">
                                <ul>
                                    <li className="time-line"><i className="bi bi-alarm" />9.10 am - 5.30 pm</li>
                                    <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                                    <li><a href="#"><i className="fa-brands fa-twitter" /></a></li>
                                    <li><a href="#"><i className="fa-brands fa-linkedin-in" /></a></li>
                                    <li><a href="#"><i className="fa-brands fa-pinterest-p" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Topheader