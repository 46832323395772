import React from "react";
import herothumb from '../assets/images/home-2/hero-thumb.png'
import heroshape from '../assets/images/home-2/hero-shape.png'
import { Link } from "react-router-dom";
const Bannerhome = () => {
    return (
        <div>
            <div className="hero-area style-two d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="hero-content">
                                <h4> IT Solution Agency in USA </h4>
                                <h1> Business Innovation</h1>
                                <h1> With IT Expert</h1>
                                <p>Professionally optimize interdependent intellectual interoperable connect
                                    best practices. Progressively fabricate done
                                </p>
                            </div>
                            <div className="hero-button">
                                <Link to="contact-us">Lets Talk<i className="bi bi-arrow-right" /></Link>                                
                                <Link className="button-two" to="about/our-company">Read More<i className="bi bi-arrow-right" /></Link>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="hero-right">
                                <div className="hero-thumb">
                                    <img src={herothumb} alt="hero thumb" />
                                </div>
                                <div className="hero-shape">
                                    <img src={heroshape} alt="hero shape" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Bannerhome