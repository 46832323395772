import axios from "axios";
import React, { useState } from "react";


const Contact = () => {
    const[formData, setFormData] = useState({name:"",email:"",phone:"",services:"",massage:""})
    const handleChange  = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleSubmit = (e) =>{
        e.preventDefault()
        axios.post('',formData).then(()=>{
            console.log('Submit Contect Data Successfully')
        })
    }
    return (
        <div>
            <div className="contact-area style-three inner">
                <div className="container">
                    <div className="row add-white-bg align-items-center">
                        <div className="col-lg-8 col-md-12">
                            <div className="single-contact-box">
                                <div className="contact-contetn">
                                    <h4>Write to Us Anytime</h4>
                                </div>
                                <form action="#">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="single-input-box">
                                                <input type="text" name="name" onChange={handleChange} placeholder="Your Name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="single-input-box">
                                                <input type="text" name="email" onChange={handleChange} placeholder="Enter E-Mail" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="single-input-box">
                                                <input type="text" name="phone" onChange={handleChange} placeholder="Phone Number" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="single-input-box">
                                                <select name="services" onChange={handleChange} id="place">
                                                    <option value="saab">Subject</option>
                                                    <option value="opel">Web Development </option>
                                                    <option value="audi">Design </option>
                                                    <option value="audi">Marketing</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="single-input-box">
                                                <textarea name="massage" onChange={handleChange} id="massage" placeholder="Write Massage" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="massage-sent-button">
                                                <button type="submit" onClick={handleSubmit}>Send Massage </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="single-contact-info-box">
                                <div className="info-content">
                                    <h4>Don’t Forget to Contact Us</h4>
                                </div>
                                <div className="contact-info-box">
                                    <div className="contact-info-icon">
                                        <i className="bi bi-telephone-fill" />
                                    </div>
                                    <div className="contact-info-content">
                                        <p>Call Us</p>
                                        <h4>+8750 (299) 299</h4>
                                    </div>
                                </div>
                                <div className="contact-info-box">
                                    <div className="contact-info-icon">
                                        <i className="bi bi-envelope-open-fill" />
                                    </div>
                                    <div className="contact-info-content">
                                        <p>Send E-Mail</p>
                                        <h4>info@dharamvirinfotech.com</h4>
                                    </div>
                                </div>
                                <div className="contact-info-box">
                                    <div className="contact-info-icon">
                                        <i className="bi bi-alarm" />
                                    </div>
                                    <div className="contact-info-content">
                                        <p>Office Hours</p>
                                        <h4>8.00 AM to 5.00 PM</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="google-map">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.325396876019!2d90.36098897592879!3d23.80702538659894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c105f81691d5%3A0x4aa9bd97de918f7b!2sSher-E-Bangla%20National%20Cricket%20Stadium!5e0!3m2!1sen!2sbd!4v1715077171980!5m2!1sen!2sbd" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Contact;