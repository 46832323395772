import React, { useState } from "react";
import axios from "axios";
import { format } from 'date-fns';
import notify from "../utility/toast";
import {ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import logo from "../assets/images/logo-2.png";
import { Link } from "react-router-dom";

const Footer = () => {
    const [data, setData] = useState({ email: "" })
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value });
    };


  console.log(data)

    const submitSubscribe = (event) => {
        event.preventDefault();
        toast("Wow so easy!");
        alert(data?.email)
    }
    return (
        <div>
            <div className="footer-area style-one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-logo">
                                <Link to="/"><img src={logo} alt="logo" /></Link>
                            </div>
                            <div className="footer-widget-desc">
                                <p>Monotonectally synergize granular top
                                    visualize strategic infomediaries afters
                                    task state of the art infrastructures
                                    digital agency in north</p>
                            </div>
                            <div className="toptech-button inner-style">
                                <Link to="/about/company-history">Discover More<i className="bi bi-arrow-right-short" /></Link>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-widget-content">
                                <div className="footer-widget-title">
                                    <h4>Company</h4>
                                </div>
                                <div className="footer-widget-menu">
                                    <ul>
                                        <li><Link to="about/our-company"><i className="bi bi-arrow-right-circle" />About</Link></li>
                                        <li><Link to="about/our-team"><i className="bi bi-arrow-right-circle" />Our Team</Link></li>
                                        <li><Link to="pricing"><i className="bi bi-arrow-right-circle" />Pricing Plan</Link></li>
                                        <li><Link to="blog"><i className="bi bi-arrow-right-circle" />Latest Blog</Link></li>
                                        <li><Link to="https://www.fairsjob.com/"><i className="bi bi-arrow-right-circle" />Careers</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-content">
                                <div className="footer-widget-title">
                                    <h4>Our Services</h4>
                                </div>
                                <div className="footer-widget-menu">
                                    <ul>
                                        <li><Link to="services/it-solutions"><i className="bi bi-arrow-right-circle" />IT Solutions</Link></li>
                                        <li><Link to="services/cyber-security"><i className="bi bi-arrow-right-circle" />Cyber Security</Link></li>
                                        <li><Link to="services/digital-marketing"><i className="bi bi-arrow-right-circle" />Digital Marketing</Link></li>
                                        <li><Link to="services/brand-identity"><i className="bi bi-arrow-right-circle" />Brand Identity</Link></li>
                                        <li><Link to="services/seo-marketing"><i className="bi bi-arrow-right-circle" />SEO Marketing</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-content">
                                <div className="footer-widget-title">
                                    <h4>Newsletter</h4>
                                </div>
                                <p className="f-desc-2">Subscribe our Latest Newsletter</p>
                                <div className="footer-newslatter-box">
                                    <input type="text" name="email" value={data?.email} onChange={handleChange} placeholder="Enter Your E-Mail" />
                                    <button onClick={submitSubscribe}>Subscribe</button>
                                    <ToastContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="footer-bottom-area">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="footer-bottom-content">
                                        <p>©2019 - {format(new Date(), 'yyyy')} Dharam vir infotech. Designed By Logiquesoft</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="footer-botton-social-icon">
                                        <ul>
                                            <li><Link to="#"><i className="fa-brands fa-facebook-f" /></Link></li>
                                            <li><Link to="#"><i className="fa-brands fa-twitter" /></Link></li>
                                            <li><Link to="#"><i className="fa-brands fa-linkedin-in" /></Link></li>
                                            <li><Link to="#"><i className="fa-brands fa-pinterest-p" /></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="search-popup">
                <button className="close-search style-two"><span className="flaticon-multiply"><i className="far fa-times-circle" /></span></button>
                <button className="close-search"><i className="bi bi-arrow-up" /></button>
                <form method="post" action="#">
                    <div className="form-group">
                        <input type="search" name="search-field" defaultValue placeholder="Search Here" required />
                        <button type="submit"><i className="fa fa-search" /></button>
                    </div>
                </form>
            </div>
        </div>

    )
}

export default Footer;