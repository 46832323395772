import React from "react";
import { Link, useLocation } from 'react-router-dom';
const Bannerinner = () => {   
    const location = useLocation();

    function convertToTitleCase(str) {
        return str
            .split('-') // Split the string by dashes
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter
            .join(' '); // Join words with a space
    }
    return (
        <div>
            <div className="breadcumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcumb-content">
                                <h4>{convertToTitleCase(location.pathname.split('/').pop())}</h4>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>&gt;</li>
                                    <li>{convertToTitleCase(location.pathname.split('/')[1])}</li>
                                    <li>&gt;</li>
                                    <li>{convertToTitleCase(location.pathname.split('/').pop())}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Bannerinner