import React from "react";
import brand1 from '../assets/images/home-2/brand-1.png'
import brand2 from '../assets/images/home-2/brand-2.png'
import brand3 from '../assets/images/home-2/brand-3.png'
import brand4 from '../assets/images/home-2/brand-4.png'
import brand5 from '../assets/images/home-2/brand-5.png'

const brands = [
    { src: brand1, alt: 'brand 1' },
    { src: brand2, alt: 'brand 2' },
    { src: brand3, alt: 'brand 3' },
    { src: brand4, alt: 'brand 4' },
    { src: brand5, alt: 'brand 5' },
  ];

const Brandarea = () => {

    return (
        <div>
            <div className="brand-area style-two">
                <div className="container">
                    <div className="row">
                        <div className="brand-list-1 owl-carousel">
                            {brands.map((brand, index) => (
                                <div className="col-lg-12" key={index}>
                                    <div className="single-brand-box">
                                        <div className="brand-thumb">
                                            <img src={brand.src} alt={brand.alt} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Brandarea