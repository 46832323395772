import React, { useState } from "react";
import { Link } from "react-router-dom";
import brand_1 from '../assets/images/home-2/brand-1.png'
import brand_2 from '../assets/images/home-2/brand-2.png'
import brand_3 from '../assets/images/home-2/brand-3.png'
import brand_4 from '../assets/images/home-2/brand-4.png'
import brand_5 from '../assets/images/home-2/brand-5.png'
import service_1 from '../assets/images/home-2/service-1.png'
import service_2 from '../assets/images/home-2/service-2.png'
import service_3 from '../assets/images/home-2/service-3.png'
import service_4 from '../assets/images/home-2/service-4.png'
import service_5 from '../assets/images/home-2/service-5.png'
import service_6 from '../assets/images/home-2/service-6.png'
import about_thumb from '../assets/images/home-2/about-thumb.png'
import about_shape from '../assets/images/home-2/about-shape.png'
import about_icon1 from '../assets/images/home-2/about-icon1.png'
import about_icon2 from '../assets/images/home-2/about-icon2.png'
import about_icon3 from '../assets/images/home-2/about-icon3.png'
import about_icon4 from '../assets/images/home-2/about-icon4.png'
import blog3 from '../assets/images/home-1/blog3.png'
import about_shape2 from '../assets/images/home-2/about-shape2.png'
import feature_icon1 from '../assets/images/home-2/feature-icon1.png'
import feature_icon2 from '../assets/images/home-2/feature-icon2.png'
import feature_icon3 from '../assets/images/home-2/feature-icon3.png'
import blog1 from '../assets/images/home-1/blog1.png'
import blog2 from '../assets/images/home-1/blog2.png'

const Home = () => {
    return (
        <div>
            <div className="feature-area style-two">
                <div className="container">
                    <div className="row add-marging">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-box">
                                <div className="feature-icon">
                                    <img src={feature_icon1} alt="icon" />
                                </div>
                                <div className="feature-content">
                                    <h4>18+ Year Experience</h4>
                                    <p>Monotonically synergize grants to business visualize strategic infomediaries</p>
                                    <Link to="service">Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-box">
                                <div className="feature-icon">
                                    <img src={feature_icon2} alt="icon" />
                                </div>
                                <div className="feature-content">
                                    <h4>Dedicated Members</h4>
                                    <p>Monotonically synergize grants to business visualize strategic infomediaries</p>
                                    <Link to="service">Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-box">
                                <div className="feature-icon">
                                    <img src={feature_icon3} alt="icon" />
                                </div>
                                <div className="feature-content">
                                    <h4>Valuable Supports</h4>
                                    <p>Monotonically synergize grants to business visualize strategic infomediaries</p>
                                    <Link to="service">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area style-two">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-left">
                                <div className="about-thumb">
                                    <img src={about_thumb} alt="icon" />
                                </div>
                                <div className="about-shape">
                                    <img src={about_shape} alt="icon" />
                                </div>
                                <div className="about-conter-box">
                                    <div className="about-counter-title">
                                        <h4>18</h4>
                                        <span>+</span>
                                        <p>Years Of <br /> Experiences </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-right">
                                <div className="section-title left style-two">
                                    <h4>IT Support For Business</h4>
                                    <h1>Ensuring Your Success Trusted</h1>
                                    <h1>IT Services Source</h1>
                                    <p>Monotonectally synergize granular markets and front markets. Collaboratively visualize strategic infomediaries after multimedia based models. Synergistically task state of the art infrastructures for</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-about-icon-box">
                                            <div className="about-icon">
                                                <img src={about_icon1} alt="icon" />
                                            </div>
                                            <div className="about-icon-box-content">
                                                <h4>100% Money Back Gaurentee</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-about-icon-box">
                                            <div className="about-icon">
                                                <img src={about_icon2} alt="icon" />
                                            </div>
                                            <div className="about-icon-box-content">
                                                <h4>Expert &amp; Dedicated Team Members</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-about-icon-box">
                                            <div className="about-icon">
                                                <img src={about_icon3} alt="icon" />
                                            </div>
                                            <div className="about-icon-box-content">
                                                <h4>24/7 Free Tehcnical Supports</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-about-icon-box">
                                            <div className="about-icon">
                                                <img src={about_icon4} alt="icon" />
                                            </div>
                                            <div className="about-icon-box-content">
                                                <h4>100% Customers Satisfaction</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="toptech-button style-one">
                                    <Link to="/about-us">About More<i className="bi bi-arrow-right-circle-fill" /></Link>
                                </div>
                                <div className="about-shape-two">
                                    <img src={about_shape2} alt="icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="service-area style-two">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="section-title center style-two">
                                <h4>IT Support For Business </h4>
                                <h1>Ensuring Your Success Trusted</h1>
                                <h1>IT Services Source</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-box">
                                <div className="service-icon-thumb">
                                    <img src={service_1} alt="icon" />
                                </div>
                                <div className="service-number">
                                    <h1>01</h1>
                                </div>
                                <div className="service-content">
                                    <h4>Graphic Design</h4>
                                    <p>Monotonectally synergize grants to business visualize strategic infomediaries</p>
                                    <Link to="service">Read More<i className="bi bi-arrow-right" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-box">
                                <div className="service-icon-thumb">
                                    <img src={service_2} alt="icon" />
                                </div>
                                <div className="service-number">
                                    <h1>02</h1>
                                </div>
                                <div className="service-content">
                                    <h4>Cloud Solutions</h4>
                                    <p>Monotonectally synergize grants to business visualize strategic infomediaries</p>
                                    <Link to="service">Read More<i className="bi bi-arrow-right" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-box">
                                <div className="service-icon-thumb">
                                    <img src={service_3} alt="icon" />
                                </div>
                                <div className="service-number">
                                    <h1>03</h1>
                                </div>
                                <div className="service-content">
                                    <h4>WP Development</h4>
                                    <p>Monotonectally synergize grants to business visualize strategic infomediaries</p>
                                    <Link to="service">Read More<i className="bi bi-arrow-right" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-box">
                                <div className="service-icon-thumb">
                                    <img src={service_4} alt="icon" />
                                </div>
                                <div className="service-number">
                                    <h1>04</h1>
                                </div>
                                <div className="service-content">
                                    <h4>Digital Marketing</h4>
                                    <p>Monotonectally synergize grants to business visualize strategic infomediaries</p>
                                    <Link to="service">Read More<i className="bi bi-arrow-right" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-box">
                                <div className="service-icon-thumb">
                                    <img src={service_5} alt="icon" />
                                </div>
                                <div className="service-number">
                                    <h1>05</h1>
                                </div>
                                <div className="service-content">
                                    <h4>3D Vector Illustration</h4>
                                    <p>Monotonectally synergize grants to business visualize strategic infomediaries</p>
                                    <Link to="service">Read More<i className="bi bi-arrow-right" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-box">
                                <div className="service-icon-thumb">
                                    <img src={service_6} alt="icon" />
                                </div>
                                <div className="service-number">
                                    <h1>06</h1>
                                </div>
                                <div className="service-content">
                                    <h4>Hosting Services</h4>
                                    <p>Monotonectally synergize grants to business visualize strategic infomediaries</p>
                                    <Link to="service">Read More<i className="bi bi-arrow-right" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="team-area style-two">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-title left style-two">
                                <h4>Dedicated Team</h4>
                                <h1>Meet Our Dedicated Member</h1>
                                <h1>For Any Enquery</h1>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="toptech-button style-one">
                                <Link to="team">All Member<i className="bi bi-arrow-right-circle-fill" /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="single-team-box">
                                <div className="team-thumb">
                                    <img src="assets/images/team/team-1.png" alt="icon" />
                                </div>
                                <div className="team-content">
                                    <h4>John D. Alexon</h4>
                                    <p>UI Designer</p>
                                    <div className="team-shape">
                                        <img src="assets/images/team/team-shape.png" alt="icon" />
                                    </div>
                                </div>
                                <div className="team-socila-icon-box">
                                    <div className="team-share">
                                        <div className="team-share-icon">
                                            <span><i className="bi bi-share" /></span>
                                        </div>
                                    </div>
                                    <div className="team-social-icon">
                                        <ul>
                                            <li><Link to="#"><i className="fab fa-facebook-f" /></Link></li>
                                            <li><Link to="#"><i className="fab fa-twitter" /></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram" /></Link></li>
                                            <li><Link to="#"><i className="fa-brands fa-pinterest-p" /></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="single-team-box">
                                <div className="team-thumb">
                                    <img src="assets/images/team/team-2.png" alt="icon" />
                                </div>
                                <div className="team-content">
                                    <h4>Alexina Gomez</h4>
                                    <p>HR Manager</p>
                                    <div className="team-shape">
                                        <img src="assets/images/team/team-shape.png" alt="icon" />
                                    </div>
                                </div>
                                <div className="team-socila-icon-box">
                                    <div className="team-share">
                                        <div className="team-share-icon">
                                            <span><i className="bi bi-share" /></span>
                                        </div>
                                    </div>
                                    <div className="team-social-icon">
                                        <ul>
                                            <li><Link to="#"><i className="fab fa-facebook-f" /></Link></li>
                                            <li><Link to="#"><i className="fab fa-twitter" /></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram" /></Link></li>
                                            <li><Link to="#"><i className="fa-brands fa-pinterest-p" /></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="single-team-box">
                                <div className="team-thumb">
                                    <img src="assets/images/team/team-3.png" alt="icon" />
                                </div>
                                <div className="team-content">
                                    <h4>Antoine Brown</h4>
                                    <p>Developer</p>
                                    <div className="team-shape">
                                        <img src="assets/images/team/team-shape.png" alt="icon" />
                                    </div>
                                </div>
                                <div className="team-socila-icon-box">
                                    <div className="team-share">
                                        <div className="team-share-icon">
                                            <span><i className="bi bi-share" /></span>
                                        </div>
                                    </div>
                                    <div className="team-social-icon">
                                        <ul>
                                            <li><Link to="#"><i className="fab fa-facebook-f" /></Link></li>
                                            <li><Link to="#"><i className="fab fa-twitter" /></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram" /></Link></li>
                                            <li><Link to="#"><i className="fa-brands fa-pinterest-p" /></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="single-team-box">
                                <div className="team-thumb">
                                    <img src="assets/images/team/team-4.png" alt="icon" />
                                </div>
                                <div className="team-content">
                                    <h4>Carolina Scott</h4>
                                    <p>UI Designer</p>
                                    <div className="team-shape">
                                        <img src="assets/images/team/team-shape.png" alt="icon" />
                                    </div>
                                </div>
                                <div className="team-socila-icon-box">
                                    <div className="team-share">
                                        <div className="team-share-icon">
                                            <span><i className="bi bi-share" /></span>
                                        </div>
                                    </div>
                                    <div className="team-social-icon">
                                        <ul>
                                            <li><Link to="#"><i className="fab fa-facebook-f" /></Link></li>
                                            <li><Link to="#"><i className="fab fa-twitter" /></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram" /></Link></li>
                                            <li><Link to="#"><i className="fa-brands fa-pinterest-p" /></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="counter-area style-two">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-6">
                            <div className="counter-box">
                                <div className="counter-content">
                                    <h4 className="counterup">10</h4>
                                    <span>K+</span>
                                    <p>happy customers</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="counter-box">
                                <div className="counter-content">
                                    <h4 className="counterup">20</h4>
                                    <span>K+</span>
                                    <p>Works Completed</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="counter-box">
                                <div className="counter-content">
                                    <h4>99</h4>
                                    <span>+</span>
                                    <p>Expert Members</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="counter-box">
                                <div className="counter-content">
                                    <h4>100</h4>
                                    <span>%</span>
                                    <p>Satisfaction Rates</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="portfolio-area style-two">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="section-title center style-two">
                                <h4>IT Support For Business</h4>
                                <h1>Ensuring Your Success Trusted</h1>
                                <h1>IT Services Source</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="portfolio_nav">
                                <div className="portfolio_menu">
                                    <ul className="menu-filtering">
                                        <li className="current_menu_item" data-filter="*"> All items </li>
                                        <li data-filter=".technology">Technology </li>
                                        <li data-filter=".designing">Designing </li>
                                        <li data-filter=".bussiness"> Bussiness </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row image_load">
                        <div className="col-lg-4 col-md-6 grid-item technology">
                            <div className="single-portfolio-box">
                                <div className="portfolio-thumb">
                                    <img src="assets/images/home-2/portfolio-1.png" alt="icon" />
                                </div>
                                <div className="portfolio-content">
                                    <div className="portfolio-number">
                                        <h1>01</h1>
                                    </div>
                                    <div className="portfolio-title">
                                        <Link to="project-details">Business Growth with Toptech Agency <i className="bi bi-plus" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 grid-item designing">
                            <div className="single-portfolio-box">
                                <div className="portfolio-thumb">
                                    <img src="assets/images/home-2/portfolio-2.png" alt="icon" />
                                </div>
                                <div className="portfolio-content">
                                    <div className="portfolio-number">
                                        <h1>02</h1>
                                    </div>
                                    <div className="portfolio-title">
                                        <Link to="project-details">Desktop Application Development <i className="bi bi-plus" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 grid-item bussiness">
                            <div className="single-portfolio-box">
                                <div className="portfolio-thumb">
                                    <img src="assets/images/home-2/portfolio-3.png" alt="icon" />
                                </div>
                                <div className="portfolio-content">
                                    <div className="portfolio-number">
                                        <h1>03</h1>
                                    </div>
                                    <div className="portfolio-title">
                                        <Link to="project-details">Illustration and Vector Design for Add <i className="bi bi-plus" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="working-proces-area style-two">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="section-title center style-two">
                                <h4>Working Process</h4>
                                <h1>We Follow the Easy Working Steps</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="working-process-box before-transprent after-transprent">
                                <div className="process-thumb">
                                    <img src="assets/images/home-2/process-1.png" alt="icon" />
                                    <div className="process-number">
                                        <span>01</span>
                                    </div>
                                </div>
                                <div className="process-content">
                                    <h4>Start A Project</h4>
                                    <p>Proactively drive maintainable value next mission-critical infrastructures done the new business
                                        to planning</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="working-process-box after-transprent">
                                <div className="process-thumb">
                                    <img src="assets/images/home-2/process-2.png" alt="icon" />
                                    <div className="process-number two">
                                        <span>02</span>
                                    </div>
                                </div>
                                <div className="process-content">
                                    <h4>Project Analysis</h4>
                                    <p>Proactively drive maintainable value next mission-critical infrastructures done the new business
                                        to planning</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="working-process-box before-transprent">
                                <div className="process-thumb">
                                    <img src="assets/images/home-2/process-3.png" alt="icon" />
                                    <div className="process-number">
                                        <span>03</span>
                                    </div>
                                </div>
                                <div className="process-content">
                                    <h4>Deliver to Success</h4>
                                    <p>Proactively drive maintainable value next mission-critical infrastructures done the new business
                                        to planning</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="testimonial-area style-one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="section-title center style-two">
                                <h4>Testimonial</h4>
                                <h1>Customer’s Awesome Feedback</h1>
                                <h1>About Our Services</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="testi-list-1 owl-carousel">
                            <div className="col-lg-12 text-center">
                                <div className="single-testimonial-box">
                                    <div className="testi-desc">
                                        <p>Completely extend leveraged customer service rather than performance based imperatives.
                                            magnetic relationships rather than leveraged e-markets. Rapidiously transform timely niches
                                            technology. Enthusiastically e-enable global e-markets for cooperative e-business.
                                            Authoritatively deliver highly efficient expertise</p>
                                    </div>
                                    <div className="testi-rating">
                                        <ul>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                        </ul>
                                    </div>
                                    <div className="testi-author">
                                        <h4>Venkatesh Iyer</h4>
                                        <p>UI Designer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 text-center">
                                <div className="single-testimonial-box">
                                    <div className="testi-desc">
                                        <p>Completely extend leveraged customer service rather than performance based imperatives.
                                            magnetic relationships rather than leveraged e-markets. Rapidiously transform timely niches
                                            technology. Enthusiastically e-enable global e-markets for cooperative e-business.
                                            Authoritatively deliver highly efficient expertise</p>
                                    </div>
                                    <div className="testi-rating">
                                        <ul>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                        </ul>
                                    </div>
                                    <div className="testi-author">
                                        <h4>John D. Alexon</h4>
                                        <p>UI Designer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 text-center">
                                <div className="single-testimonial-box">
                                    <div className="testi-desc">
                                        <p>Completely extend leveraged customer service rather than performance based imperatives.
                                            magnetic relationships rather than leveraged e-markets. Rapidiously transform timely niches
                                            technology. Enthusiastically e-enable global e-markets for cooperative e-business.
                                            Authoritatively deliver highly efficient expertise</p>
                                    </div>
                                    <div className="testi-rating">
                                        <ul>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                        </ul>
                                    </div>
                                    <div className="testi-author">
                                        <h4>Alex Brock </h4>
                                        <p>UI Designer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 text-center">
                                <div className="single-testimonial-box">
                                    <div className="testi-desc">
                                        <p>Completely extend leveraged customer service rather than performance based imperatives.
                                            magnetic relationships rather than leveraged e-markets. Rapidiously transform timely niches
                                            technology. Enthusiastically e-enable global e-markets for cooperative e-business.
                                            Authoritatively deliver highly efficient expertise</p>
                                    </div>
                                    <div className="testi-rating">
                                        <ul>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                            <li><i className="bi bi-star-fill" /></li>
                                        </ul>
                                    </div>
                                    <div className="testi-author">
                                        <h4>Abhishek Sharma</h4>
                                        <p>UI Designer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="video-area style-two">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="section-title center style-two">
                                <h4>Watch Now</h4>
                                <h1>Our Best Working Process System</h1>
                                <h1>with Team Expert Leaders</h1>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="video-button">
                                <Link className="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true" href="https://www.youtube.com/watch?v=e6R6VsgD8yQ&amp;t=179s"><i className="bi bi-play-fill" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact-info-area style-two">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-box">
                                <div className="contact-info-icon">
                                    <i className="fa-solid fa-phone" />
                                </div>
                                <div className="contact-info-content">
                                    <h4>Call us Any time</h4>
                                    <p>+8750 (299) 299</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-box">
                                <div className="contact-info-icon">
                                    <i className="fa-regular fa-envelope" />
                                </div>
                                <div className="contact-info-content">
                                    <h4>Send E-Mail</h4>
                                    <p>info@dharamvirinfotech.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-box">
                                <div className="contact-info-icon">
                                    <i className="fa-regular fa-clock" />
                                </div>
                                <div className="contact-info-content">
                                    <h4>Opening Hours</h4>
                                    <p>Mon - Fri (8.00 - 5.00)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-area style-one home-two">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="section-title center style-two">
                                <h4>Latest Blog</h4>
                                <h1>Latest Inshights From Our Blog</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-box">
                                <div className="blog-thumb">
                                    <img src={blog1} alt="icon" />
                                </div>
                                <div className="blog-content">
                                    <div className="blog-meta">
                                        <span><i className="bi bi-calendar-check-fill" /> December 14, 2024</span>
                                    </div>
                                    <Link to="blog-details">Most Popular Chrome Extensionfor Business Promot</Link>
                                </div>
                                <div className="blog-buttom">
                                    <div className="blog-author">
                                        <span><i className="bi bi-person-circle" />By Admin </span>
                                    </div>
                                    <div className="blog-button">
                                        <Link to="blog-grid">Read More<i className="bi bi-arrow-right-short" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-box">
                                <div className="blog-thumb">
                                    <img src={blog2} alt="icon" />
                                </div>
                                <div className="blog-content">
                                    <div className="blog-meta">
                                        <span><i className="bi bi-calendar-check-fill" /> December 14, 2024</span>
                                    </div>
                                    <Link to="blog-details">How to Secure your facebook Business Account </Link>
                                </div>
                                <div className="blog-buttom">
                                    <div className="blog-author">
                                        <span><i className="bi bi-person-circle" />By Admin </span>
                                    </div>
                                    <div className="blog-button">
                                        <Link to="blog-grid">Read More<i className="bi bi-arrow-right-short" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-box">
                                <div className="blog-thumb">
                                    <img src={blog3} alt="brand" />
                                </div>
                                <div className="blog-content">
                                    <div className="blog-meta">
                                        <span><i className="bi bi-calendar-check-fill" /> December 14, 2024</span>
                                    </div>
                                    <Link to="blog-details">Better Context Menus With Safe Triangles</Link>
                                </div>
                                <div className="blog-buttom">
                                    <div className="blog-author">
                                        <span><i className="bi bi-person-circle" />By Admin </span>
                                    </div>
                                    <div className="blog-button">
                                        <Link to="blog-grid">Read More<i className="bi bi-arrow-right-short" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="brand-area style-two">
                <div className="container">
                    <div className="row">
                        <div className="brand-list-1 owl-carousel">
                            <div className="col-lg-12">
                                <div className="single-brand-box">
                                    <div className="brand-thumb">
                                        <img src={brand_1} alt="brand" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="single-brand-box">
                                    <div className="brand-thumb">
                                        <img src={brand_2} alt="brand" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="single-brand-box">
                                    <div className="brand-thumb">
                                        <img src={brand_3} alt="brand" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="single-brand-box">
                                    <div className="brand-thumb">
                                        <img src={brand_4} alt="brand" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="single-brand-box">
                                    <div className="brand-thumb">
                                        <img src={brand_5} alt="brand" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Home;